let prevRatio = 3

const applyRatio = ratio => {
  $('#left-container').css('width', '')
  $('#left-container').removeClass('col-md-' + prevRatio).addClass('col-md-' + ratio)
  $('#right-container').removeClass('col-md-' + (12 - prevRatio)).addClass('col-md-' + (12 - ratio))
  prevRatio = ratio
}

$('#left-container').resizable({
  helper: 'js-tree-resizable-helper',
  handles: 'e',
  // ghost: true,
  autoHide: true,
  stop: (e, ui) => {
    let total = $('.content-wrapper').width()
    let min = total / 6 + 0.5
    let max = total - min
    let width = ui.size.width + 50
    if (width > max) applyRatio(10)
    else if (width < min) applyRatio(2)
    else applyRatio(Math.floor(width * 12 / total))
  }
})
