import '../css/app.css'
import './resizable.js'

$(function() {

    const cses = {
      '/en/': '011173105431564622430:ifxicgwdxfi',
      '/uk/': '011173105431564622430:wbcwxjqd6vw'
    }
    var lang = window.location.pathname.match(/^\/([\w]{2})\//g)[0]
    if (app.loadPreviewArticle) {
      $.get("/article.html?v=" + new Date().getTime(), (html) => {
        $("[data-identifier='readers']").html(html)
      })
      return
    }

    function versionParam () {
      return `?v=${app.timestamp}`
    }
    function Tree(options) {
      var plugins = ["types", "contextmenu"];
      this.$el = options.$el;
      this.handleSelected = options.handleSelected;
      this.$el
        .jstree({
          core: {
            data: {
              url: function(node) {
                var url =
                  lang +"json/children/" +
                  (node.id === "#" ? null : node.id) +
                  ".json" + versionParam();
                return url;
              },
              success: function(folders) {
                $.each(folders, function(index, node) {
                  node.state = node.state || {};
                  node.state.loaded = false;
                  if (node.childrenCnt > 0) {
                    node.state.closed = true;
                    node.children = true;
                  } else {
                    node.state = "leaf";
                  }
                  node.parent = node.parent || "#";
                  if (node.isFolder) {
                    node.icon = "fa fa-folder-o";
                  } else {
                    node.icon = "fa fa-file-o";
                  }
                  node.a_attr = {
                    href: lang + "entity2305843015656313960/view/" + node.id
                    // title: "double.click.to.open",
                    // "data-toggle": "tooltip"
                  };
                });
                return folders;
              }
            },
            force_text: true,
            themes: {
              name: "proton",
              responsive: true
            }
          },
          contextmenu: {
            items: function($node) {
              var menuItems = {};
              if ($node.original.childrenCnt > 0) {
                menuItems["Expand"] = {
                  label: "Expand",
                  icon: "fa fa-level-down",
                  action: function(data) {
                    var id = data.reference[0].attributes.href.value;
                    api.expandSubTree(id);
                  }
                };
              }
              return menuItems;
            }
          },
          plugins: plugins
        })
        .on("select_node.jstree", function(e, _data) {
          if (that._selectedNode.id !== _data.node.id) {
            if (that._selectedNode.id) {
              _data.instance.deselect_node(that._selectedNode);
            }
            that._selectedNode = _data.node;
            var jstree = that.$el.jstree(true);
            jstree.open_node(that._selectedNode.id, function() {});
            that.handleSelected(that._selectedNode.id, true);
          }
        });
      var that = this;
      that._selectedNode = {};
  
      var api = {
        expandSubTree: function(nodeId) {
          var jstree = that.$el.jstree(true);
          jstree.open_node(nodeId, function(node) {
            _.forEach(node.children, function(child) {
              api.expandSubTree(child);
            });
          });
        },
  
        openParents: function(parents, callback) {
          var jstree = that.$el.jstree(true);
          if (parents.length) {
            var pid = parents[0];
            parents.shift();
            if (!jstree.get_node(pid).state.loaded) {
              jstree.load_node(pid, () => {
                jstree.open_node(pid);
                this.openParents(parents, callback);
              });
            } else {
              jstree.open_node(pid);
              this.openParents(parents, callback);
            }
          } else {
            callback();
          }
        },
  
        selectNode: function(id) {
          that.$el.jstree(true).select_node(id);
        }
      };
  
      this.$el.bind("ready.jstree", () => {
        var loc = location.href;
        var myId = loc.substr(loc.lastIndexOf("/") + 1);
        app.selectedId = myId;
        
        app.parents.pop()
        api.openParents(app.parents, () => {
          api.selectNode(myId);
        });
      });
  
      return api;
    }
  
    app.hideLoading();
  
    function loadArticle(id, callback) {
      $.get(lang+"json/article/" + id + ".json" + versionParam(), callback);
    }
  
    function registerClicks () {
      $('.my-link').off('click').on('click', e => {
        if ($(e.currentTarget).attr('href').length > 35) {
          e.preventDefault()
          let id = $(e.currentTarget).attr('href').substr(35)
          handleSelected(id)
        }
      })
    }
    function getTranslationLang() {
      if (lang == '/en/') {
        return '/uk/'
      } else {
        return '/en/'
      }
    }
    function handleSelected(id, fromTree) {
      if (id != app.selectedId) {
        hideTree()
        console.log("selected", id);
        loadArticle(id, function(article) {
          $("[data-identifier='readers']").html(article.content);
          document.title = article.name + ' · Codejig'
          history.pushState({ "cjg-doc": id }, "", lang + "entity2305843015656313960/view/" + id);
          app.selectedId = id;
          buildBreadcrump(article.breadcrumbs);
          $('[data-identifier="back"]').html(prevButton(article.prev))
          $('[data-identifier="next"]').html(nextButton(article.next))
          $('[data-identifier="lang-switcher"]').attr('href', getTranslationLang() + "entity2305843015656313960/view/" + id)
          let parents = article.breadcrumbs.map(a => a.id)
          parents.pop()
          
          if (!fromTree) {
            tree.openParents(parents, function () {
              tree.selectNode(id)
            })
          }
  
          registerClicks();
        });
      }
    }
  
    var tree = new Tree({
      $el: $("#tree-container"),
      handleSelected: handleSelected
    });
  
    function buildBreadcrump(path) {
      let li = "";
      for (let i = 0; i < path.length - 1; ++i) {
        li += `<li><a class="my-link" href="${lang}entity2305843015656313960/view/${path[i].id}">${path[i].name}</a></li>`;
      }
      li += `<li>${path[path.length - 1].name}</li>`;
      $("#breadcrumbs-container").html(`<ul class="breadcrumb">
                                    ${li}
                                  </ul>`);
    }
  
  
    function prevButton(item) {
      return !item ? '' : `<a class="btn btn-link btn-md my-link" href="${lang}entity2305843015656313960/view/${item.id}">
                <span class="fa fa-chevron-left" style="color:#A5A5A5 "></span>
                <span class="translatable en">${item.name}</span>
              </a>`
    }
  
    function nextButton(item) {
      return !item ? '' : `<a class="btn btn-link btn-md my-link" href="${lang}entity2305843015656313960/view/${item.id}">
                <span class="translatable en">${item.name}</span>
                <span class="fa fa-chevron-right" style="color:#A5A5A5 "></span>
              </a>`
    }
  
    registerClicks()
  
    var leftContainer = $('#left-container')
    var rightContainer = $('#right-container')
  
    function hideTree () {
      rightContainer.removeClass('hidden-on-mobile')
      leftContainer.addClass('hidden-on-mobile')
    }
  
    function showTree () {
      rightContainer.addClass('hidden-on-mobile')
      leftContainer.removeClass('hidden-on-mobile')
    }
  
    var closeBtn = $('<button type="button" class="btn btn-box-tool box-tools pull-right shown-on-mobile"><i class="fa fa-minus"></i></button>')
    closeBtn.click(hideTree)
    leftContainer.find('.box-header').append(closeBtn)
    
    var foldersBtn = $('<a type="button" class="folders-switcher shown-on-mobile" style="margin-right: 4px"><span class="folder-icon fa fa-folder-o"></span></a>')
    foldersBtn.click(showTree)
    rightContainer.find('.box-body').prepend(foldersBtn)

    window.onpopstate = function (e) {
      handleSelected(e.state['cjg-doc'])
    };
  
    var previousSearchValue
    console.log("ready");

    function handleEsc(e) {
      if (e.key == 'Escape') {
        hideModal()
      }
    }
    function showModal() {
      document.getElementById('modal-container').style.display = 'block'
      document.getElementById('insert-target').style.height = '100vh'
      document.body.addEventListener('keydown', handleEsc)
    }
    function hideModal() {
      document.getElementById('modal-container').style.display = 'none'
      document.getElementById('insert-target').style.height = ''
      document.body.removeEventListener('keydown', handleEsc)
    }

    document.getElementById('modal-container').addEventListener('click', () => {
      hideModal()
    })
    function makeQuery(query){
      return fetch("https://www.googleapis.com/customsearch/v1/siterestrict?key=AIzaSyAv6DZ3GRSaCVYGHxCIABujy96zjn70aaU&cx="+cses[lang] +"&q="+query, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((a) => {
        return a.json()
      })
    }

    function createNodeWithTextAndClass(tag, text, clas) {
      const node = document.createElement(tag)
      if (text){
        const textNode = document.createTextNode(text)
        node.appendChild(textNode)
      }
      if (clas) {
        node.classList.add(clas)
      }
      return node
    }
    function renderItems(parent, items) {
      items.forEach((item) => {
        const elem = createNodeWithTextAndClass('div', '', 'item')
        const title = createNodeWithTextAndClass('div', item.title, 'title')
        const link = createNodeWithTextAndClass('a', '', 'my-link')
        link.setAttribute('href', item.link.substring(24))
        const desc = createNodeWithTextAndClass('p', item.snippet, 'description')
        link.appendChild(title)
        elem.appendChild(link)
        link.appendChild(desc)
        parent.appendChild(elem)
      })
    }
    function initSearch() {
      let value = document.getElementById('search-input').value
      if (value){
        if (value == previousSearchValue) {
          showModal()
        } else {
          previousSearchValue = value
          makeQuery(value).then((q) => {
            document.getElementById('modal').innerHTML = ''
            if (q.items) {
              renderItems(document.getElementById('modal'), q.items)
              showModal()
              registerClicks()
            } else {
              document.getElementById('modal').appendChild(createNodeWithTextAndClass('div', 'Nothing found', ''))
              showModal()
            }
          })
        }
      }
    }
    document.getElementById('search-button').addEventListener('click', initSearch)
    document.getElementById('search-input').addEventListener('keydown', (e) => {
      if (e.key == 'Enter') {
        initSearch()
      }
    })
  });
  